import * as React from "react"
import PageLayout from "../../../components/page-layout"
import ExternalLink from "../../../components/external-link"
import Navbar from "../../../components/navbar"
import { Link } from "gatsby"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import ClappyCheeksImg from "./img/clappy-cheeks.gif"

import "../style.css"
import "./style.css"
import FadeIn from "react-fade-in/lib/FadeIn"

const ClappyCheeksPage = () => {
  return (
    <PageLayout title="clappy cheeks | grac">
      <body id="clappy-cheeks-page" className="page">
        <div className="center-container">
          <Navbar />
          <FadeIn>
            {/* PROJECTS */}
            <div className="page-block">
              <div>
                <p>
                  <Link to="/projects">&larr; back</Link>
                </p>
                <div style={{ height: "20px" }} />
                <h2>Clappy Cheeks</h2>
                <p className="project-description">
                  <em>built in 2021</em>&nbsp;//&nbsp;
                  <ExternalLink href="https://github.com/creating-deez/clappy-cheeks">
                    Source Code
                  </ExternalLink>
                </p>

                <ExternalLink href="https://clappy-cheeks.netlify.app/">
                  <button>View Project &rarr;</button>
                </ExternalLink>
                <div style={{ height: "30px" }} />
                <div>
                  {/* <h3>about</h3> */}
                  <p>
                    pls don't ask why we built this
                    <br />
                  </p>
                </div>
                <img
                  src={ClappyCheeksImg}
                  style={{ width: "80%", maxWidth: "300px" }}
                />
              </div>
            </div>
          </FadeIn>
        </div>
      </body>
    </PageLayout>
  )
}

export default ClappyCheeksPage
